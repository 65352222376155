var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"callPlatform"},[_vm._m(0),_vm._m(1),_c('section',{staticClass:"part3"},[_c('span',{staticClass:"part-title"},[_vm._v("呼叫中心平台特殊优势")]),_c('div',{staticClass:"part3_main reveal-bottom2"},_vm._l((_vm.part3Data),function(item,index){return _c('div',{key:index,staticClass:"part3-item",on:{"mouseover":function($event){return _vm.part3ItemOver(index)},"mouseout":function($event){return _vm.part3ItemOut(index)}}},[_c('div'),_c('div',[_c('img',{attrs:{"src":item.img,"alt":""}})]),_c('div',_vm._l((item.p),function(item2,index2){return _c('p',{key:index2},[_vm._v(" "+_vm._s(item2)+" ")])}),0)])}),0),_c('div',{staticClass:"part3_main reveal-bottom2"},[_c('swiper',{attrs:{"options":_vm.swiperOption}},[_c('swiper-slide',{staticClass:"chance-slide0"},_vm._l((_vm.part3Datas[0]),function(item,index){return _c('div',{key:index,staticClass:"part3-item",style:({
              width: '1.77rem',
              height: '4.31rem',
              background: 'url(' + item.bg + ') no-repeat',
              backgroundSize: '100% 100%',
            }),on:{"click":function($event){return _vm.part3Moblie0(index)}}},[_c('div'),_c('div',[_c('img',{attrs:{"src":item.img,"alt":""}})]),_c('div',_vm._l((item.p),function(item2,index2){return _c('p',{key:index2},[_vm._v(" "+_vm._s(item2)+" ")])}),0)])}),0),_c('swiper-slide',{staticClass:"chance-slide1"},_vm._l((_vm.part3Datas[1]),function(item,index){return _c('div',{key:index,staticClass:"part3-item",style:({
              width: '1.77rem',
              height: '4.31rem',
              background: 'url(' + item.bg + ') no-repeat 100% 100%',
              backgroundSize: '100% 100%',
            }),on:{"click":_vm.part3Moblie0}},[_c('div'),_c('div',[_c('img',{attrs:{"src":item.img,"alt":""}})]),_c('div',_vm._l((item.p),function(item2,index2){return _c('p',{key:index2},[_vm._v(" "+_vm._s(item2)+" ")])}),0)])}),0)],1),_c('div',{staticClass:"swiper-pagination swiper_bg_blue",attrs:{"slot":"pagination"},slot:"pagination"})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"part1"},[_c('div',{staticClass:"part1_main"},[_c('div',{staticClass:"part1-title reveal-left"},[_c('span',[_vm._v("呼叫中心平台")]),_c('p',[_vm._v(" 百悟呼叫中心平台作为通过多种通信手段为客户提供交互式自助服务和人工服务的系统，通常提供业务咨询、信息查询、帐务查询、投诉与建议、业务受理、交易处理、电话回访、客户关怀、市场调查、数据整理、电话营销等综合性服务。 ")]),_c('a',{staticClass:"zhiCustomBtn"},[_vm._v("了解更多")])]),_c('img',{staticClass:"reveal-right",attrs:{"src":require("../../assets/img/product/callPlatform/part1-icon.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"part2"},[_c('span',{staticClass:"part-title"},[_vm._v("智能客服业务架构介绍")]),_c('img',{staticClass:"reveal-bottom2",attrs:{"src":require("../../assets/img/product/callPlatform/part2-icon.png"),"alt":""}})])
}]

export { render, staticRenderFns }