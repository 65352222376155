var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"honest"},[_vm._m(0),_c('section',{staticClass:"part-jump reveal-bottom2"},[_c('div',{staticClass:"pJ_main"},[_c('ul',_vm._l((_vm.pJump),function(item,i){return _c('li',{key:i,on:{"click":function($event){return _vm.pJClick($event, i, item.address)}}},[_c('a',{attrs:{"href":"#"},domProps:{"textContent":_vm._s(item.span)}})])}),0)])]),_c('section',{staticClass:"part2"},[_c('span',{staticClass:"part-title reveal-bottom2"},[_vm._v("廉洁合规")]),_c('div',{staticClass:"part2_main"},[_c('p',{staticClass:"reveal-bottom2"},[_vm._v(" 商无信不立，业无诚不远，遵守商业道德规范是企业可持续发展的基石。百悟科技要求每位员工以商业道德规范为工作行为基本准则，坚定地推行内部规范化管理，通过建立完善的企业合规管理体系，开展员工的法律法规及管理制度规范的培训工作，有效提升了员工的企业合规风险管理意识。每个企业公平诚信、合法经营，才能使整个营商环境健康有序发展，百悟倡导廉洁的企业文化，希望与行业伙伴一同推动行业健康发展。 ")]),_c('div',{staticClass:"part2_item p2_item-1 reveal-bottom2",on:{"click":function($event){return _vm.p2Click(0)}}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"part2_item p2_item-2 reveal-bottom2",on:{"click":function($event){return _vm.p2Click(1)}}},[_vm._m(3),_vm._m(4)]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"part1"},[_c('div',{staticClass:"part1_main"},[_c('div',{staticClass:"part1-title reveal-left"},[_c('span',[_vm._v("廉洁合规")])]),_c('img',{staticClass:"reveal-right",attrs:{"src":require("../../assets/img/about/honest/part1-icon.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p2_iContent p2_iContent_1"},[_c('div',{staticClass:"p2_iContent-left"},[_c('span',[_vm._v("内审合规部")]),_c('h6',[_vm._v("主要职责：")]),_c('p',[_vm._v(" 对内部员工涉及的严重违规情况进行调查，并根据调查力求发现公司业务和管理中存在的风险。 ")]),_c('p',[_vm._v(" 针对发现的风险，努力推动公司相关部门进行改进，包括制度的完善和执行。 ")]),_c('p',[_vm._v(" 通过各种渠道在公司范围内开展企业合规的宣传和培训，落实合规要求。 ")])]),_c('div',{staticClass:"p2_iContent-right"},[_c('img',{attrs:{"src":require("../../assets/img/about/honest/part2-icon1.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p2_iContent p2_iContent_2"},[_c('div',{staticClass:"p2_iContent-top"},[_c('div',[_c('span',[_vm._v("内审合规部")]),_c('h6',[_vm._v("主要职责：")])]),_c('img',{attrs:{"src":require("../../assets/img/about/honest/part2-icon1.png"),"alt":""}})]),_c('div',{staticClass:"p2_iContent-bottom"},[_c('p',[_vm._v(" 对内部员工涉及的严重违规情况进行调查，并根据调查力求发现公司业务和管理中存在的风险。 ")]),_c('p',[_vm._v(" 针对发现的风险，努力推动公司相关部门进行改进，包括制度的完善和执行。 ")]),_c('p',[_vm._v(" 通过各种渠道在公司范围内开展企业合规的宣传和培训，落实合规要求。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p2_iContent p2_iContent_1"},[_c('div',{staticClass:"p2_iContent-left"},[_c('span',[_vm._v("关于举报")]),_c('p',[_vm._v(" 本平台受理员工营私舞弊、弄虚作假等违反职业道德的行为的投诉和举报，其他问题的受理请拨打电话010-50950599-8783或联系业务人员反馈。 ")]),_c('p',[_vm._v(" 支持匿名举报，但鼓励实名举报，便于及时反馈受理情况及通报处理结果。 ")]),_c('p',[_vm._v(" 对反馈人信息进行严格保密是我们最基本职责。任何人不得对反馈人实行打击报复行为，一经发现，严肃处理。 ")])]),_c('div',{staticClass:"p2_iContent-right"},[_c('img',{attrs:{"src":require("../../assets/img/about/honest/part2-icon2.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p2_iContent p2_iContent_2"},[_c('div',{staticClass:"p2_iContent-top"},[_c('div',[_c('span',[_vm._v("关于举报")])]),_c('img',{attrs:{"src":require("../../assets/img/about/honest/part2-icon2.png"),"alt":""}})]),_c('div',{staticClass:"p2_iContent-bottom"},[_c('p',[_vm._v(" 本平台受理员工营私舞弊、弄虚作假等违反职业道德的行为的投诉和举报，其他问题的受理请拨打电话010-50950599-8783或联系业务人员反馈。 ")]),_c('p',[_vm._v(" 支持匿名举报，但鼓励实名举报，便于及时反馈受理情况及通报处理结果。 ")]),_c('p',[_vm._v(" 对反馈人信息进行严格保密是我们最基本职责。任何人不得对反馈人实行打击报复行为，一经发现，严肃处理。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p2_down reveal-bottom2"},[_c('div',{staticClass:"p2-dItem"},[_c('img',{attrs:{"src":require("../../assets/img/about/honest/part2-dIcon1.png"),"alt":""}}),_c('p',[_vm._v("举报邮箱：jiancha@baiwutong.com")])]),_c('div',{staticClass:"p2-dItem"},[_c('img',{attrs:{"src":require("../../assets/img/about/honest/part2-dIcon2.png"),"alt":""}}),_c('p',[_vm._v(" 来信来访： 北京市朝阳区阜通东大街融科望京中心B座9层 内审合规部 ")])])])
}]

export { render, staticRenderFns }