import { render, staticRenderFns } from "./WisdomMedia.vue?vue&type=template&id=29c5f332&scoped=true"
import script from "./WisdomMedia.vue?vue&type=script&lang=js"
export * from "./WisdomMedia.vue?vue&type=script&lang=js"
import style0 from "./WisdomMedia.vue?vue&type=style&index=0&id=29c5f332&prod&lang=less&scoped=true"
import style1 from "./WisdomMedia.vue?vue&type=style&index=1&id=29c5f332&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29c5f332",
  null
  
)

export default component.exports