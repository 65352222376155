import { render, staticRenderFns } from "./TelephoneCard.vue?vue&type=template&id=47fa5942&scoped=true"
import script from "./TelephoneCard.vue?vue&type=script&lang=js"
export * from "./TelephoneCard.vue?vue&type=script&lang=js"
import style0 from "./TelephoneCard.vue?vue&type=style&index=0&id=47fa5942&prod&lang=less&scoped=true"
import style1 from "./TelephoneCard.vue?vue&type=style&index=1&id=47fa5942&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47fa5942",
  null
  
)

export default component.exports