import { render, staticRenderFns } from "./InternationalMarketingSms.vue?vue&type=template&id=90209b56&scoped=true"
import script from "./InternationalMarketingSms.vue?vue&type=script&lang=js"
export * from "./InternationalMarketingSms.vue?vue&type=script&lang=js"
import style0 from "./InternationalMarketingSms.vue?vue&type=style&index=0&id=90209b56&prod&lang=less&scoped=true"
import style1 from "./InternationalMarketingSms.vue?vue&type=style&index=1&id=90209b56&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90209b56",
  null
  
)

export default component.exports