<template>
  <footer>
    <div class="mobile-footer-ul">
      <ul>
        <li class="click-li">
          <div @click="clickFooterLi($event)">
            <p>热门产品</p>
            <span>></span>
          </div>
          <ul class="ul-one">
            <li v-for="(item, i) in p1Data" :key="i" @click.stop.prevent>
              <router-link
                :to="item.link"
                v-text="item.span"
                @click.native="linkRun"
              ></router-link>
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li class="click-li">
          <div @click="clickFooterLi($event)">
            <p>解决方案</p>
            <span>></span>
          </div>
          <ul class="ul-one">
            <li v-for="(item, i) in p2Data" :key="i" @click.stop.prevent>
              <router-link
                :to="item.link"
                v-text="item.span"
                @click.native="linkRun"
              ></router-link>
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li class="click-li">
          <div @click="clickFooterLi($event)">
            <p>生态与合作</p>
            <span>></span>
          </div>
          <ul class="ul-one">
            <li>
              <a style="cursor: pointer;" @click="pushTo">合作伙伴</a>
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li class="click-li">
          <div @click="clickFooterLi($event)">
            <p>其他</p>
            <span>></span>
          </div>
          <ul class="ul-one">
            <li v-for="(item, i) in p4Data" :key="i" @click.stop.prevent>
              <router-link
                :to="item.link"
                v-text="item.span"
                @click.native="linkRun"
              ></router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="footer">
      <ul class="footer-top">
        <li>
          <img src="../assets/img/footer/footerTop-1.png" alt="" />
          <p>1V1大客户服务</p>
        </li>
        <li>
          <img src="../assets/img/footer/footerTop-2.png" alt="" />
          <p>7*24小时技术咨询</p>
        </li>
        <li>
          <img src="../assets/img/footer/footerTop-3.png" alt="" />
          <p>秒级故障响应</p>
        </li>
        <li>
          <img src="../assets/img/footer/footerTop-4.png" alt="" />
          <p>免费咨询与备案</p>
        </li>
        <li>
          <img src="../assets/img/footer/footerTop-5.png" alt="" />
          <p>反馈与服务建议</p>
        </li>
      </ul>
      <div class="footer-main">
        <div class="footer-main-item">
          <p>热门产品</p>
          <div class="fm-item-main">
            <router-link to="/voice">95语音</router-link>
            <router-link to="/messageServe">短信服务</router-link>
            <router-link to="/internationalSms">国际短信</router-link>
            <router-link to="/5GMessage">5G消息</router-link>
            <router-link to="/memberSMS">会员短信</router-link>
            <router-link to="/fuseMessage">融合通信平台</router-link>
            <router-link to="/noticeSMS">通知短信</router-link>
            <router-link to="/callPlatform">呼叫中心</router-link>
            <router-link to="/5GMessagePlatform">5G消息平台</router-link>
            <router-link to="/number95">码号服务</router-link>
          </div>
        </div>
        <div class="footer-main-item">
          <p>解决方案</p>
          <div class="fm-item-main">
            <router-link to="/ecommerce">电商解决方案</router-link>
            <router-link to="/transportation">交通运输解决方案</router-link>
            <router-link to="/O2O">O2O解决方案</router-link>
            <router-link to="/AI">人工智能解决方案</router-link>
            <router-link to="/finance">金融解决方案</router-link>
            <router-link to="/mobileInternet">移动互联网解决方案</router-link>
            <router-link to="/governmentAndEnterprise"
              >政企解决方案</router-link
            >
            <router-link to="/smallAndMediumsizedEnterprises"
              >中小企业通信解决方案</router-link
            >
          </div>
        </div>
        <div class="footer-main-item">
          <p>生态与合作</p>
          <div class="fm-item-main">
            <!-- <a href="/#partNew">合作伙伴</a> -->
            <p style="color: #606879; cursor: pointer;" @click="pushTo">合作伙伴</p>
          </div>
        </div>
        <div class="footer-main-item">
          <p>其他</p>
          <div class="fm-item-main">
            <a href="news/news.html"> 新闻动态 </a>
            <router-link to="/join">诚聘英才</router-link>
            <router-link to="/about#part1">企业介绍</router-link>
            <a style="cursor: pointer;" @click="pushToPrivacy">隐私政策</a>
            <router-link to="/about#part3">团队发展</router-link>
            <router-link to="/about#part6">联系我们</router-link>
            <p>
              <!-- 9521781<br /> -->
              010-50950599
            </p>
          </div>
        </div>
        <div class="footer-main-item">
          <p>关注百悟</p>
          <div class="fm-item-main">
            <img src="../assets/img/footer/QRcode.png" alt="" />
            <p>百悟科技公众号</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-buttom">
      <p>
        友情链接：<a href="https://www.jiweitech.com/">厦门集微科技有限公司</a
        >
        <!-- &nbsp;|&nbsp;<a href="https://www.sikongtech.com/"
          >北京思空科技有限公司</a
        >&nbsp;|&nbsp;<a href="https://www.51hexuan.com/">和选商城</a> -->
      </p>
      <p>
        京ICP备10213541号-1&nbsp;&nbsp;&nbsp;
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502035238"
          ><img src="../assets/img/footer/police.png" alt="" /></a
        >&nbsp;&nbsp;&nbsp;京公网安备 11010502035238号
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      p1Data: [
        {
          span: '95语音',
          link: '/voice',
        },
        {
          span: '短信服务',
          link: '/messageServe',
        },
        {
          span: '国际短信',
          link: '/internationalSms',
        },
        {
          span: '5G消息',
          link: '/5GMessage',
        },
        {
          span: '会员短信',
          link: '/memberSMS',
        },
        {
          span: '融合通信平台',
          link: '/fuseMessage',
        },
        {
          span: '通知短信',
          link: '/noticeSMS',
        },
        {
          span: '呼叫中心',
          link: '/callPlatform',
        },
        {
          span: '5G消息平台',
          link: '/5GMessagePlatform',
        },
        {
          span: '码号服务',
          link: '/number95',
        },
      ],
      p2Data: [
        {
          span: '电商解决方案',
          link: '/ecommerce',
        },
        {
          span: '交通运输解决方案',
          link: '/transportation',
        },
        {
          span: 'O2O解决方案',
          link: '/O2O',
        },
        {
          span: '人工智能解决方案',
          link: '/AI',
        },
        {
          span: '金融解决方案',
          link: '/finance',
        },
        {
          span: '移动互联网解决方案',
          link: '/mobileInternet',
        },
        {
          span: '政企解决方案',
          link: '/governmentAndEnterprise',
        },
        {
          span: '中小企业通信解决方案',
          link: '/smallAndMediumsizedEnterprises',
        },
      ],
      p4Data: [
        {
          span: '诚聘英才',
          link: '/join',
        },
        {
          span: '企业介绍',
          link: '/about#part1',
        },
        {
          span: '团队发展',
          link: '/about#part3',
        },
        {
          span: '联系我们',
          link: '/about#part6',
        },
      ],
    };
  },
  methods: {
    pushToPrivacy() {
      this.$router.push('/privacy');
    },
    clickFooterLi(e) {
      // debugger
      if ($(e.currentTarget).parent().find('.ul-one').is('.active')) {
        $('.ul-one').removeClass('active');
      } else {
        $('.ul-one').removeClass('active');
        $(e.currentTarget).parent().find('.ul-one').addClass('active');
      }
    },
    // 跳转页面
    linkRun() {
      // $('.mobile-footer-ul').stop().slideUp();
      // $('.nav_img').attr(
      //   'src',
      //   require('../assets/img/header/mobile/header-icon.png')
      // );
    },
    pushTo() {
      window.sessionStorage.setItem('cec', 'partNew')
      this.$router.push({ path: "/" });
    }
  }
};
</script>

<style lang="less" scoped>
footer {
  box-sizing: border-box;
  width: 100%;
  // height: 713px;
  background-color: rgb(40, 43, 49);
  padding-top: 67px;
  .mobile-footer-ul {
    display: none;
    margin: 0.3rem 0;
    li {
      width: 100%;
      > a,
      div {
        width: calc(100% - 1.34rem);
        height: 50px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .ul-one {
        width: 100%;
        overflow: hidden;
        transition: max-height 1s;
        max-height: 0px;
        li {
          border: 0px;
          background: transparent;
          padding-left: 0.2rem;
          >a {
            font-size: 0.27rem;
          }
        }
      }
      .ul-one.active {
        max-height: 700px;
      }
    }
  }
  .footer {
    width: 1110px;
    margin: 0 auto;
    .footer-top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 95px;
      li {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #ffffff;
        img {
          width: 25px;
          height: 25px;
          margin-right: 9px;
        }
      }
    }
    .footer-main {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-bottom: 103px;
      .footer-main-item {
        width: 233px;
        > p {
          display: block;
          width: 100%;
          font-size: 18px;
          font-weight: bold;
          color: #eaeaea;
          margin-bottom: 31px;
        }
        .fm-item-main {
          width: 194px;
          height: 180px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          a {
            font-size: 14px;
            color: #606879;
          }
          p {
            font-size: 14px;
            color: #a7aebc;
            line-height: 22px;
          }
        }
      }
      .footer-main-item:nth-of-type(2) {
        width: 339px;
      }
      .footer-main-item:nth-of-type(2) .fm-item-main {
        width: 280px;
        height: 143px;
      }
      .footer-main-item:nth-of-type(3) {
        width: 189px;
      }
      .footer-main-item:nth-of-type(3) .fm-item-main {
        grid-template-columns: 1fr;
        width: 60px;
        height: 70px;
      }
      .footer-main-item:nth-of-type(4) {
        width: 225px;
      }
      .footer-main-item:nth-of-type(4) .fm-item-main {
        grid-template-columns: 1fr;
        width: 97px;
        height: 239px;
      }
      .footer-main-item:last-child {
        flex: 1;
        > p {
          text-align: center;
          margin-bottom: 35px;
        }
        .fm-item-main {
          display: block;
          width: 100%;
          img {
            width: 100%;
            height: auto;
            margin-bottom: 18px;
          }
          p {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 16px;
            color: #a7aebc;
          }
        }
      }
    }
  }
  .footer-buttom {
    width: 1110px;
    margin: 0 auto;
    p {
      font-size: 14px;
      color: #606879;
      img {
        margin-bottom: -5px;
      }
    }
    a {
      font-size: 14px;
      color: #a7aebc;
    }
    p:first-child {
      margin-bottom: 9px;
    }
  }
}
@media screen and (max-width: 768px) {
  footer {
    // height: 7rem;
    padding-top: 0.25rem;
    display: flex;
    flex-direction: column;
    .mobile-footer-ul {
      display: block;
      margin: inherit;
      margin-bottom: 0.4rem;
      li {
        width: 100%;
        > a,
        div {
          width: calc(100% - 1.34rem);
          height: 50px;
          margin: 0 auto;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          font-size: 0.3rem;
          color: #dcdfe6;
        }
        .ul-one {
          width: 100%;
          overflow: hidden;
          transition: max-height 1s;
          max-height: 0px;
          li {
            border: 0px;
            background: transparent;
          }
        }
        .ul-one.active {
          max-height: 700px;
        }
      }
    }
    .footer {
      width: calc(100% - 2.32rem);
      overflow: hidden;
      padding-bottom: 0.6rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .footer-top {
        width: 50%;
        height: 2.73rem;
        flex-direction: column;
        margin-bottom: 0rem;
        li {
          height: 0.35rem;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          font-size: 0.2rem;
          line-height: 0.2rem;
        }
        li:nth-of-type(1) {
          img {
            width: 0.33rem;
            height: 0.33rem;
            margin-right: 0.26rem;
          }
        }
        li:nth-of-type(2) {
          img {
            width: 0.28rem;
            height: 0.3rem;
            margin-right: 0.31rem;
          }
        }
        li:nth-of-type(3) {
          img {
            width: 0.3rem;
            height: 0.3rem;
            margin-right: 0.29rem;
          }
        }
        li:nth-of-type(4) {
          img {
            width: 0.27rem;
            height: 0.31rem;
            margin-right: 0.32rem;
          }
        }
        li:nth-of-type(5) {
          img {
            width: 0.24rem;
            height: 0.26rem;
            margin-right: 0.35rem;
          }
        }
      }
      .footer-main {
        width: 1.6rem;
        margin-bottom: 0rem;
        margin-top: 0rem;
        .footer-main-item {
          display: none;
        }
        .footer-main-item:last-child {
          display: block;
          width: 100%;
          > p {
            margin-bottom: 0.28rem;
            font-size: 0.24rem;
            font-weight: bold;
            color: #eaeaea;
          }
          .fm-item-main {
            img {
              margin-bottom: 0.22rem;
            }
            p {
              font-size: 0.2rem;
              font-weight: 400;
              color: #d2d2d2;
            }
          }
        }
      }
    }
    .footer-buttom {
      box-sizing: border-box;
      flex: 1;
      width: 100%;
      background: #17181c;
      text-align: center;
      padding-top: 0.35rem;
      padding-bottom: 0.35rem;
      p {
        font-size: 0.16rem;
        font-weight: 400;
        color: #606879;
        img {
          width: 0.18rem;
          height: 0.2rem;
          margin-bottom: -0.05rem;
        }
      }
      a {
        font-size: 0.16rem;
        font-weight: 400;
        color: #a7aebc;
      }
      p:first-child {
        margin-bottom: 0.11rem;
      }
    }
  }
}
</style>
